import React from "react";
import { BsCalendar } from "react-icons/bs";
import { FaFileAlt } from "react-icons/fa";
import { FiMoreHorizontal } from "react-icons/fi";

const TransactionList = () => {
  return (
    <div className="text-black dark:text-white flex flex-col gap-4">
      <div className="flex flex-col gap-1 sm:flex-row sm:justify-between">
        <h4 className="font-bold">Transactions</h4>

        <div className="flex items-center gap-2">
          <button className="flex items-center gap-1.5 bg-pearl-white px-3 py-1 border border-black dark:border-white font-medium">
            <BsCalendar className="w-3 h-3" />
            <p className="text-sm">August 2023</p>
          </button>
          <button className="flex items-center gap-1.5 bg-pearl-white px-3 py-1 border border-black dark:border-white font-medium">
            <FaFileAlt className="w-3 h-3" />
            <p className="text-sm">Export to CSV</p>
          </button>
        </div>
      </div>

      <div className="transactions-list">
        <div className="bg-white dark:bg-black p-3 border-black dark:border-white flex gap-2 justify-between md:p-4">
          <div className="flex gap-2">
            <img
              src="https://d39lxsrz40jt15.cloudfront.net/downloads/EDG2012/o_1bsuejoft1ie9ho010tc1rnlnp9m_w600_h0.png"
              alt=""
              className="w-8 h-8 rounded-full object-fill"
            />

            <div>
              <p className="font-semibold text-base">
                Vodafone account top up ****04
              </p>
              <p className="text-sm text-dark-slate">Phone payment</p>
            </div>
          </div>

          <div className="flex items-center gap-2 md:gap-5">
            <div className="flex flex-col items-end text-right">
              <p className="font-semibold text-base">~ $4.20</p>
              <p className="text-sm text-dark-slate">4 Aug 1:00 PM</p>
            </div>

            <FiMoreHorizontal className="w-5 h-5 cursor-pointer hidden hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear md:block" />
          </div>
        </div>

        <div className="bg-white dark:bg-black p-3 border-black dark:border-white flex gap-2 justify-between md:p-4">
          <div className="flex gap-2">
            <img
              src="https://d39lxsrz40jt15.cloudfront.net/downloads/EDG2012/o_1bsuejoft1ie9ho010tc1rnlnp9m_w600_h0.png"
              alt=""
              className="w-8 h-8 rounded-full object-fill"
            />

            <div>
              <p className="font-semibold text-base">
                Vodafone account top up ****04
              </p>
              <p className="text-sm text-dark-slate">Phone payment</p>
            </div>
          </div>

          <div className="flex items-center gap-2 md:gap-5">
            <div className="flex flex-col items-end text-right">
              <p className="font-semibold text-base">~ $4.20</p>
              <p className="text-sm text-dark-slate">4 Aug 1:00 PM</p>
            </div>

            <FiMoreHorizontal className="w-5 h-5 cursor-pointer hidden hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear md:block" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default TransactionList;
