import React, { useRef, useState } from "react";

const AlertAnimations = () => {
  const [showAlert, setShowAlert] = useState(false);
  const [alertType, setAlertType] = useState("");
  const audioRef = useRef(null);

  const handleClick = (type) => {
    setShowAlert(true);
    setAlertType(type);
    setTimeout(() => {
      setShowAlert(false);
      setAlertType("");
    }, 4000);
    playSound();
  };

  const playSound = () => {
    audioRef.current.play();
  };

  const splitTextIntoSpans = (text) => {
    let isAlternate = false;

    return [...text].map((char, index) => {
      isAlternate = !isAlternate;

      return (
        <span
          key={index}
          className={
            isAlternate
              ? "animate-alternate-scale-rotate"
              : "animate-alternate-scale"
          }
        >
          {char}
        </span>
      );
    });
  };

  return (
    <div className="relative w-full min-h-[100vh]">
      <div className="flex flex-col gap-3 w-full h-[100vh] items-center justify-center">
        <button
          className="bg-black text-white font-medium px-4 py-2 rounded-md"
          onClick={() => handleClick("alertType1")}
        >
          Alert Type 1
        </button>
        <button
          className="bg-black text-white font-medium px-4 py-2 rounded-md"
          onClick={() => handleClick("alertType2")}
        >
          Alert Type 2
        </button>
        <button
          className="bg-black text-white font-medium px-4 py-2 rounded-md"
          onClick={() => handleClick("alertType3")}
        >
          Alert Type 3
        </button>
      </div>

      <audio ref={audioRef} src={require("../../assets/alert.wav")} />

      {showAlert && alertType === "alertType1" && (
        <div className="px-4 absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-6 items-center sm:gap-8">
          <img
            src="https://cdn.streamlabs.com/static/imgs/streamlabs-logos/app-icon/streamlabs-app-icon.png"
            alt=""
            className="min-w-[200px] w-[20vw] animate-bounce-up-down"
          />

          <div className="w-full flex flex-col items-center text-center animate-opacity-0-to-1 sm:gap-1">
            <h2 className="outlined-text text-2xl font-bold vsm:text-3xl sm:text-5xl">
              {splitTextIntoSpans("John")} donated {splitTextIntoSpans("$62")}!
            </h2>
            <p className="outlined-text text-black font-medium sm:text-lg">
              This is a test donation for $62.
            </p>
          </div>
        </div>
      )}

      {showAlert && alertType === "alertType2" && (
        <div className="p-4 absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col gap-4 items-center md:gap-6">
          <img
            src="https://cdn.streamlabs.com/static/imgs/streamlabs-logos/app-icon/streamlabs-app-icon.png"
            alt=""
            className="min-w-[200px] w-[20vw] animate-bounce-up-down"
          />

          <div className="text-center absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center animate-opacity-0-to-1 sm:gap-1">
            <h2 className="outlined-text text-2xl font-bold vsm:text-4xl sm:text-5xl">
              {splitTextIntoSpans("John")} donated {splitTextIntoSpans("$62")}!
            </h2>
            <p className="outlined-text text-black font-medium sm:text-lg">
              This is a test donation for $62.
            </p>
          </div>
        </div>
      )}

      {showAlert && alertType === "alertType3" && (
        <div className="p-4 absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex gap-4 items-center justify-center sm:gap-8 md:gap-10">
          <img
            src="https://cdn.streamlabs.com/static/imgs/streamlabs-logos/app-icon/streamlabs-app-icon.png"
            alt=""
            className="min-w-[100px] w-[17vw] animate-bounce-up-down vsm:min-w-[130px] sm:min-w-[160px]"
          />

          <div className="text-center flex flex-col items-center animate-opacity-0-to-1 sm:gap-1">
            <h2 className="outlined-text text-2xl font-bold vsm:text-3xl sm:text-4xl md:text-5xl">
              {splitTextIntoSpans("John")} donated {splitTextIntoSpans("$62")}!
            </h2>
            <p className="outlined-text text-black font-medium sm:text-lg">
              This is a test donation for $62.
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AlertAnimations;
