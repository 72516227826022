import React from "react";
import CurlyIconDark from "../../assets/icons/curlyIcon-dark.svg";
import CurlyIcon from "../../assets/icons/curlyIcon.svg";
import Gantt1Dark from "../../assets/icons/gantt1-dark.png";
import Gantt1 from "../../assets/icons/gantt1.png";
import Gantt2Dark from "../../assets/icons/gantt2-dark.png";
import Gantt2 from "../../assets/icons/gantt2.png";
import Gantt3Dark from "../../assets/icons/gantt3-dark.png";
import Gantt3 from "../../assets/icons/gantt3.png";
import CardImageDark from "../../assets/images/gatsharan-dark.png";
import CardImage from "../../assets/images/gatsharan.png";
import MoneyInCardDark from "../../assets/images/moneyin-dark.png";
import MoneyInCard from "../../assets/images/moneyin.png";
import PendingCardDark from "../../assets/images/pending-dark.png";
import PendingCard from "../../assets/images/pending.png";
import SpotifyCoverDark from "../../assets/images/spotify-cover-dark.png";
import SpotifyCover from "../../assets/images/spotify-cover.png";

const RingAndCardsDesign = () => {
  let isDarkMode;
  if (window.matchMedia("(prefers-color-scheme: dark)").matches) {
    isDarkMode = true;
  } else {
    isDarkMode = false;
  }

  return (
    <div className="hidden sm:flex justify-end relative pt-4 -right-[35%] sm:w-[50%] sm:-right-[20%] md:w-[55%]">
      <div className="w-[70vw] h-[70vw] min-w-[350px] min-h-[350px] relative sm:w-[50vw] sm:h-[50vw] lg:w-[45vw] lg:h-[45vw]">
        <div className="relative w-[95%] h-[95%] border-2 dark:border-white rounded-full flex items-center justify-center">
          <div className="absolute pb-[100%] rotate-[-30deg]">
            <div className="w-4 h-4 bg-black dark:bg-white rounded-full"></div>
          </div>

          <div className="w-[8%] absolute top-[3%] left-[35%] rotate-[90deg]">
            <img
              src={isDarkMode ? CurlyIconDark : CurlyIcon}
              alt=""
              className="text-white"
            />
          </div>

          <div className="w-[25%] absolute top-[22%] -left-[4%] z-10 shadow-blackish-sm dark:shadow-grayish-sm md:shadow-blackish md:dark:shadow-grayish">
            <img src={isDarkMode ? CardImageDark : CardImage} alt="" />
          </div>

          <div className="w-[30%] absolute top-[6%] -left-[20%] z-10 shadow-blackish-sm dark:shadow-grayish-sm md:shadow-blackish md:dark:shadow-grayish">
            <img src={isDarkMode ? PendingCardDark : PendingCard} alt="" />
          </div>

          <div className="w-[30%] absolute top-[60%] -left-[25%] z-10 shadow-blackish-sm dark:shadow-grayish-sm md:shadow-blackish md:dark:shadow-grayish">
            <img src={isDarkMode ? MoneyInCardDark : MoneyInCard} alt="" />
          </div>

          <div className="w-[25%] absolute top-[80%] -left-[15%]">
            <img src={isDarkMode ? Gantt2Dark : Gantt2} alt="" />
          </div>

          <div className="w-[30%] absolute top-[88%] left-0">
            <img src={isDarkMode ? Gantt3Dark : Gantt3} alt="" />
          </div>

          <div className="w-[25%] absolute top-[95%] -left-[10%] flex flex-col-reverse gap-1 items-center md:gap-1.5">
            <div className="bg-black dark:bg-white w-[70%] h-[3px] rounded-2xl md:h-[4px]"></div>
            <div className="bg-black dark:bg-white w-[45%] h-[3px] rounded-lg md:h-[4px]"></div>
          </div>

          <div className="w-[70%] h-[70%] absolute border-2 rounded-full flex items-center justify-center dark:border-white">
            <div className="absolute pb-[100%] rotate-[10deg]">
              <div className="w-4 h-4 bg-black dark:bg-white rounded-full"></div>
            </div>

            <div className="absolute pb-[100%] rotate-[-150deg] animate-dot2">
              <div className="w-4 h-4 bg-black dark:bg-white rounded-full"></div>
            </div>

            <div className="max-w-[45%] absolute top-[13%] left-[20%] flex flex-col gap-2 md:gap-3">
              <div>
                <img src={isDarkMode ? Gantt1Dark : Gantt1} alt="" />
              </div>

              <div className="flex flex-col gap-1 items-center md:gap-1.5">
                <div className="bg-black dark:bg-white w-[70%] h-[3px] rounded-2xl md:h-[4px]"></div>
                <div className="bg-black dark:bg-white w-[45%] h-[3px] rounded-lg md:h-[4px]"></div>
              </div>
            </div>

            <div className="w-[10%] absolute top-[65%] left-[8%]">
              <img src={isDarkMode ? CurlyIconDark : CurlyIcon} alt="" />
            </div>

            <div className="max-w-[45%] absolute top-[35%] left-[25%] shadow-blackish-sm dark:shadow-grayish-sm md:shadow-blackish md:dark:shadow-grayish">
              <img src={isDarkMode ? SpotifyCoverDark : SpotifyCover} alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RingAndCardsDesign;
