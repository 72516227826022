import React from "react";
import { AiOutlineDesktop } from "react-icons/ai";
import { FaAngleLeft } from "react-icons/fa";
import { Link } from "react-router-dom";
import QR from "../assets/images/qr.png";
import AuthCard from "../components/Login-Signup/AuthCard";
import RingAndCardsDesign from "../components/Login-Signup/RingAndCardsDesign";

const SigninWithQRPage = () => {
  return (
    <div className="bg-container bg-[url('./assets/images/light-bg.jpg')] dark:bg-[url('./assets/images/dark-bg.jpg')] bg-contain bg-no-repeat min-h-[100vh] flex bg-light-bg dark:bg-dark-bg">
      <div className="w-full flex flex-col items-center sm:flex-row-reverse">
        <RingAndCardsDesign />

        <AuthCard>
          <Link to="/">
            <button className="border dark:border-white p-1 rounded-[3px] sm:hidden">
              <FaAngleLeft className="text-xl text-black dark:text-white" />
            </button>
          </Link>

          <div className="w-full h-full flex flex-col items-center justify-center gap-8">
            <div className="flex flex-col gap-1 sm:self-start">
              <h1 className="leading-tight dark:text-white">Log in with QR</h1>

              <p className="text-dark-slate">
                Scan this code with the mobile app to log in instantly
              </p>
            </div>

            <div className="w-[55%] lg:w-[50%]">
              <div className="max-w-[360px] border p-2 rounded-sm dark:border-white">
                <img src={QR} alt="" />
              </div>

              <div className="flex items-center gap-2 pt-8 sm:relative sm:-left-[20%]">
                <hr className="flex-1 border dark:border-white sm:flex-none sm:w-[60%]" />
                <p className="font-semibold dark:text-white">or</p>
                <hr className="flex-1 border dark:border-white sm:flex-none sm:w-[60%] " />
              </div>
            </div>

            <Link to="/signin" className="w-full">
              <button className="w-full flex items-center justify-center gap-2 bg-none border border-black dark:border-white rounded-[3px] py-3 px-2 lg:gap-4">
                <AiOutlineDesktop className="text-xl lg:text-2xl text-black dark:text-white" />
                <p className="text-[14px] font-semibold dark:text-white lg:text-[16px]">
                  Log in with email or phone number
                </p>
              </button>
            </Link>

            <div className="hidden sm:flex flex-col items-center gap-1 lg:self-start lg:flex-row lg:gap-2 2xl:mt-[10%]">
              <p className="dark:text-white">You don't have an account?</p>
              <Link to="/signup" className="font-bold dark:text-white">
                Create an account
              </Link>
            </div>
          </div>
        </AuthCard>
      </div>
    </div>
  );
};

export default SigninWithQRPage;
