import React from "react";
import {
  BsArrowDownLeft,
  BsArrowUpRight,
  BsBarChartFill,
} from "react-icons/bs";
import { IoPieChartOutline } from "react-icons/io5";

const TransactionData = () => {
  return (
    <div className="h-fit grid grid-cols-1 gap-4 md:grid-cols-2 xl:col-span-2">
      <div className="bg-white dark:bg-black border border-black dark:border-white p-4 text-black dark:text-white flex flex-col gap-4">
        <div className="flex justify-between">
          <div>
            <p className="font-medium">Balance</p>
            <h5 className="font-bold">$80,470.50</h5>
          </div>

          <IoPieChartOutline className="w-5 h-5" />
        </div>

        <div className="bg-pale-green h-2">
          <div className="bg-[#6AA277] h-full w-[75%]"></div>
        </div>
      </div>

      <div className="bg-white dark:bg-black border border-black dark:border-white p-4 text-black dark:text-white flex flex-col gap-4">
        <div className="flex justify-between">
          <div>
            <p className="font-medium">Money in</p>
            <h5 className="font-bold">$60,930.50</h5>
          </div>

          <BsArrowDownLeft className="w-5 h-5" />
        </div>

        <div className="bg-pale-yellow h-2">
          <div className="bg-[#AEA272] h-full w-[75%]"></div>
        </div>
      </div>

      <div className="bg-white dark:bg-black border border-black dark:border-white p-4 text-black dark:text-white md:col-span-2">
        <div className="relative max-w-full box-content grid grid-cols-1 gap-x-[10%] gap-y-8 vsm:grid-cols-2 vsm:gap-y-4">
          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <p className="font-medium text-dark-slate">Money in</p>
              <h5 className="font-bold">$80,470.50</h5>
              <p>Amount you gained</p>
            </div>

            <BsArrowDownLeft className="w-5 h-5" />
          </div>

          <div className="h-[2px] w-full absolute top-[7rem] left-1/2 transform -translate-x-1/2 -translate-y-1/2  border border-black dark:border-white border-dashed vsm:w-[2px] vsm:h-[50%] vsm:top-12"></div>

          <div className="flex justify-between">
            <div className="flex flex-col gap-1">
              <p className="font-medium text-dark-slate">Money out</p>
              <h5 className="font-bold">$80,470.50</h5>
              <p>Amount you spent</p>
            </div>

            <BsArrowUpRight className="w-5 h-5" />
          </div>

          <button className="text-black flex items-center justify-center gap-1.5 bg-purple-lighter px-5 py-2 border border-black font-semibold transition duration-200 ease-linear hover:bg-purple-lighter-hover vsm:col-span-2">
            <BsBarChartFill className="w-4 h-4" />
            <p className="font-bold">Financial Report</p>
          </button>
        </div>
      </div>
    </div>
  );
};

export default TransactionData;
