import React from "react";
import { BsCalendar } from "react-icons/bs";
import { FaSearch } from "react-icons/fa";

const RecentDonations = () => {
  return (
    <div className="h-fit flex flex-col text-black dark:text-white bg-white dark:bg-black border border-black dark:border-white">
      <div className="flex items-center justify-between p-3 md:p-4 border-b border-black dark:border-white">
        <h4 className="text-lg font-semibold md:text-xl">Recent Donations</h4>
        <BsCalendar className="w-4 h-4" />
      </div>

      <div className="p-3 md:p-4 pt-4 pb-2 md:pt-6">
        <div className="flex items-center gap-2 bg-white dark:bg-black px-2 md:px-4 py-2 border border-black dark:border-white">
          <FaSearch className="w-3 h-3 md:w-4 md:h-4" />
          <input
            type="text"
            placeholder="Type to search"
            className="w-full bg-white dark:bg-black outline-none text-sm md:text-base font-medium placeholder:text-black placeholder:dark:text-white placeholder:font-light"
          />
        </div>
      </div>

      <div className="recent-transactions">
        <div className="flex gap-3 md:gap-4 justify-between p-3 md:p-4 border-b border-black dark:border-white">
          <div className="flex flex-col gap-1">
            <h6 className="font-semibold text-sm md:text-base">
              Donat Twerski
            </h6>
            <p className="font-light text-xs md:text-sm">
              08 Nov 2023 - IOTask Web UI Kit
            </p>
          </div>
          <div>
            <h6 className="font-semibold text-sm md:text-base">$25.00</h6>
          </div>
        </div>

        <div className="flex gap-2 md:gap-4 justify-between p-3 md:p-4 border-b border-black dark:border-white">
          <div className="flex flex-col gap-1">
            <h6 className="font-semibold text-sm md:text-base">
              Donat Twerski
            </h6>
            <p className="font-light text-xs md:text-sm">
              08 Nov 2023 - IOTask Web UI Kit
            </p>
          </div>
          <div>
            <h6 className="font-semibold text-sm md:text-base">$25.00</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentDonations;
