import React from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import AlertAnimations from "./components/AlertStudio/AlertAnimations";
import AlertStudioPage from "./pages/AlertStudioPage";
import DashboardPage from "./pages/DashboardPage";
import ForgotPasswordPage from "./pages/ForgotPasswordPage";
import SigninPage from "./pages/SigninPage";
import SigninWithQRPage from "./pages/SigninWithQRPage";
import SignupPage from "./pages/SignupPage";
import SplashScreenPage from "./pages/SplashScreenPage";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<SplashScreenPage />} />
      </Routes>
      <Routes>
        <Route path="/signin" element={<SigninPage />} />
      </Routes>
      <Routes>
        <Route path="/signup" element={<SignupPage />} />
      </Routes>
      <Routes>
        <Route path="/signinQR" element={<SigninWithQRPage />} />
      </Routes>
      <Routes>
        <Route path="/forgotPassword" element={<ForgotPasswordPage />} />
      </Routes>
      <Routes>
        <Route path="/dashboard" element={<DashboardPage />} />
      </Routes>
      <Routes>
        <Route path="/alertStudio" element={<AlertStudioPage />} />
      </Routes>
      <Routes>
        <Route path="/alertAnimations" element={<AlertAnimations />} />
      </Routes>
    </Router>
  );
}

export default App;
