import React from "react";
import RecentDonations from "../components/Dashboard/RecentDonations";
import TransactionsData from "../components/Dashboard/TransactionsData";
import TransactionsList from "../components/Dashboard/TransactionsList";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";

const DashboardPage = () => {
  return (
    <div className="flex w-full min-h-[100vh] bg-light-bg dark:bg-dark-bg">
      <Sidebar />

      <div className="flex flex-col justify-between  text-black dark:text-white p-6 w-full md-2:ml-[280px] md-2:flex-1 md-2:relative md-2:px-6 md-2:py-0 lg:px-10">
        <div>
          <Header currentPage="Dashboard" pageInfo="Sales Overview" />

          <div className="mt-5 mb-16 grid grid-cols-1 gap-y-8 md-2:mb-5 xl:grid-cols-3 xl:gap-y-0 xl:gap-x-8">
            <div className="flex flex-col gap-8 xl:col-span-2">
              <TransactionsData />

              <TransactionsList />
            </div>

            <RecentDonations />
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default DashboardPage;
