import React from "react";
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";
import Dropdown from "../common/Dropdown";

const GeneralForm = () => {
  const fontList = ["Font A", "Font B", "Font C", "Font D", "Font E", "Font F"];

  return (
    <form
      action=""
      className="my-4 grid grid-cols-1 gap-y-4 md:grid-cols-2 md:gap-x-4 md:gap-y-6 md-2:grid-cols-1 lg:grid-cols-2"
    >
      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="alertName" className="font-medium">
          Alert Name
        </label>
        <input
          type="text"
          id="alertName"
          placeholder="Start typing ..."
          className="w-full p-4 outline-none font-medium bg-white dark:bg-black text-black dark:text-white border border-black dark:border-white placeholder:text-dark-slate text-sm vsm:text-base"
        />
      </div>

      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="minimumAmount" className="font-medium">
          Minimum Amount
        </label>
        <div className="w-full flex gap-2 justify-between p-4 bg-white dark:bg-black border border-black dark:border-white">
          <div>
            <p className="text-sm vsm:text-base">Choose amount</p>
          </div>

          <div className="flex items-center gap-2">
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlineMinusCircle className="text-2xl" />
            </button>
            <input
              type="text"
              id="minimumAmount"
              placeholder="0"
              className="w-6 vsm:w-8 outline-none text-center font-medium bg-white dark:bg-black text-black dark:text-white placeholder:text-dark-slate"
            />
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlinePlusCircle className="text-2xl" />
            </button>
          </div>
        </div>
      </div>

      <Dropdown
        dropdownList={fontList}
        dropdownLabel="Font"
        dropdownPlaceholder="Select font"
      />

      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="fontSize" className="font-medium">
          Font Size
        </label>
        <div className="w-full flex gap-2 justify-between p-4 bg-white dark:bg-black border border-black dark:border-white">
          <div>
            <p className="text-sm vsm:text-base">Select font size</p>
          </div>

          <div className="flex items-center gap-2">
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlineMinusCircle className="text-2xl" />
            </button>
            <input
              type="text"
              id="fontSize"
              placeholder="0"
              className="w-6 vsm:w-8 outline-none text-center font-medium bg-white dark:bg-black text-black dark:text-white placeholder:text-dark-slate"
            />
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlinePlusCircle className="text-2xl" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="fontColor" className="font-medium">
          Font Color
        </label>
        <div className="w-full flex gap-2 justify-between p-4 bg-white dark:bg-black border border-black dark:border-white">
          <div>
            <p className="text-sm vsm:text-base">#00000</p>
          </div>

          <div>
            <input
              type="color"
              id="fontColor"
              className="cursor-pointer"
              value="#000"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="highlightColor" className="font-medium">
          Font Highlight Color
        </label>
        <div className="w-full flex gap-2 justify-between p-4 bg-white dark:bg-black border border-black dark:border-white">
          <div>
            <p className="text-sm vsm:text-base">#00000</p>
          </div>

          <div>
            <input
              type="color"
              id="highlightColor"
              className="cursor-pointer"
              value="#000"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col gap-2 md:col-span-2 md-2:col-span-1 lg:col-span-2">
        <label htmlFor="messageTemplate" className="font-medium">
          Message Template
        </label>
        <input
          type="text"
          id="messageTemplate"
          placeholder="{{sender}} Donated RS {{amount}} ."
          className="p-4 outline-none font-medium bg-white dark:bg-black text-black dark:text-white border border-black dark:border-white placeholder:text-dark-slate text-sm vsm:text-base"
        />
      </div>

      <button className="w-fit mt-4 bg-black dark:bg-dark-slate text-white text-sm px-4 py-2 rounded-[2px] vsm:text-base md:mt-8">
        Save Changes
      </button>
    </form>
  );
};

export default GeneralForm;
