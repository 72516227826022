import React, { useState } from "react";
import LivePreview from "../assets/images/live-preview.png";
import Button from "../components/AlertStudio/Button";
import GeneralForm from "../components/AlertStudio/GeneralForm";
import MediaForm from "../components/AlertStudio/MediaForm";
import Footer from "../components/common/Footer";
import Header from "../components/common/Header";
import Sidebar from "../components/common/Sidebar";

const AlertStudioPage = () => {
  const [activeButton, setActiveButton] = useState("General");

  const handleButtonClick = (btnName) => {
    setActiveButton(btnName);
  };

  const formComponent =
    activeButton === "General" ? (
      <GeneralForm />
    ) : activeButton === "Media" ? (
      <MediaForm />
    ) : null;

  return (
    <div className="flex w-full min-h-[100vh] bg-light-bg dark:bg-dark-bg">
      <Sidebar />

      <div className="flex flex-col justify-between text-black dark:text-white p-6 w-full md-2:ml-[280px] md-2:flex-1 md-2:relative md-2:px-6 md-2:py-0 lg:px-10">
        <div>
          <Header currentPage="Alert Studio" pageInfo="Alert Studio" />

          <div className="mt-5 mb-16 flex flex-col gap-6 xl:flex-row">
            <div className="w-full bg-white dark:bg-black border border-black dark:border-white p-4 md:p-6 xl:w-[65%]">
              <div className="flex gap-2 vsm:gap-4">
                <Button
                  btnName="General"
                  activeButton={activeButton}
                  handleButtonClick={handleButtonClick}
                />
                <Button
                  btnName="Media"
                  activeButton={activeButton}
                  handleButtonClick={handleButtonClick}
                />
                <Button
                  btnName="Paid"
                  activeButton={activeButton}
                  handleButtonClick={handleButtonClick}
                />
              </div>

              {formComponent}
            </div>

            <div className="flex flex-col gap-4 xl:w-[35%]">
              <h5 className="font-medium">Live Preview</h5>

              <div className="border border-black dark:border-white">
                <img src={LivePreview} alt="" className="w-full" />
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </div>
  );
};

export default AlertStudioPage;
