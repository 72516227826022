import React from "react";
import { HiOutlineMinusCircle, HiOutlinePlusCircle } from "react-icons/hi";
import { RxUpload } from "react-icons/rx";
import Dropdown from "../common/Dropdown";

const MediaForm = () => {
  const fontList = ["Font A", "Font B", "Font C", "Font D", "Font E", "Font F"];

  return (
    <form
      action=""
      className="my-4 grid grid-cols-1 gap-y-4 md:grid-cols-2 md:gap-x-4 md:gap-y-6 md-2:grid-cols-1 lg:grid-cols-2"
    >
      <div className="flex flex-col items-baseline gap-2">
        <label className="font-medium">Image</label>
        <div className="relative bg-purple-lighter w-full h-32">
          <div className="w-full h-full bg-[#ffffff70] dark:bg-[#00000070]">
            <label htmlFor="image">
              <RxUpload className="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl text-black dark:text-white" />
            </label>
            <input
              type="file"
              src=""
              alt=""
              id="image"
              className="w-6 h-6 invisible absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-baseline gap-2">
        <label className="font-medium">Audio</label>
        <div className="relative bg-purple-lighter w-full h-32">
          <div className="w-full h-full bg-[#ffffff70] dark:bg-[#00000070]">
            <label htmlFor="audio">
              <RxUpload className="cursor-pointer absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 text-3xl text-black dark:text-white" />
            </label>
            <input
              type="file"
              src=""
              alt=""
              id="audio"
              className="w-6 h-6 invisible absolute z-10 top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
            />
          </div>
        </div>
      </div>

      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="audioVolume" className="font-medium">
          Audio Volume
        </label>
        <div className="w-full flex gap-2 justify-between p-4 bg-white dark:bg-black border border-black dark:border-white">
          <div>
            <p className="text-sm vsm:text-base">Set volume</p>
          </div>

          <div className="flex items-center gap-2">
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlineMinusCircle className="text-2xl" />
            </button>
            <input
              type="text"
              id="audioVolume"
              placeholder="0"
              className="w-6 vsm:w-8 outline-none text-center font-medium bg-white dark:bg-black text-black dark:text-white placeholder:text-dark-slate"
            />
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlinePlusCircle className="text-2xl" />
            </button>
          </div>
        </div>
      </div>

      <Dropdown
        dropdownList={fontList}
        dropdownLabel="Layout"
        dropdownPlaceholder="Select layout"
      />

      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="alertDuration" className="font-medium">
          Alert Duration
        </label>
        <div className="w-full flex gap-2 justify-between p-4 bg-white dark:bg-black border border-black dark:border-white">
          <div>
            <p className="text-sm vsm:text-base">Set volume</p>
          </div>

          <div className="flex items-center gap-2">
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlineMinusCircle className="text-2xl" />
            </button>
            <input
              type="text"
              id="alertDuration"
              placeholder="0"
              className="w-6 vsm:w-8 outline-none text-center font-medium bg-white dark:bg-black text-black dark:text-white placeholder:text-dark-slate"
            />
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlinePlusCircle className="text-2xl" />
            </button>
          </div>
        </div>
      </div>

      <div className="flex flex-col items-baseline gap-2">
        <label htmlFor="alertDelay" className="font-medium">
          Alert Delay
        </label>
        <div className="w-full flex gap-2 justify-between p-4 bg-white dark:bg-black border border-black dark:border-white">
          <div>
            <p className="text-sm vsm:text-base">Set volume</p>
          </div>

          <div className="flex items-center gap-2">
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlineMinusCircle className="text-2xl" />
            </button>
            <input
              type="text"
              id="alertDelay"
              placeholder="0"
              className="w-6 vsm:w-8 outline-none text-center font-medium bg-white dark:bg-black text-black dark:text-white placeholder:text-dark-slate"
            />
            <button className=" text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
              <HiOutlinePlusCircle className="text-2xl" />
            </button>
          </div>
        </div>
      </div>

      <div className="w-fit flex border border-black lg:col-span-2">
        <button className="px-2 py-1 bg-purple-lighter text-black dark:text-white">
          ON
        </button>
        <button className="px-2 py-1 bg-light-bg dark:bg-dark-bg text-black dark:text-white">
          OFF
        </button>
      </div>

      <button className="w-fit mt-4 bg-black dark:bg-dark-slate text-white text-sm px-4 py-2 rounded-[2px] vsm:text-base md:mt-8">
        Save Changes
      </button>
    </form>
  );
};

export default MediaForm;
