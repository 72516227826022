import React from "react";
import { FaBell, FaSearch } from "react-icons/fa";
import { IoAddCircleSharp, IoExtensionPuzzleSharp } from "react-icons/io5";

const Header = (props) => {
  return (
    <header className="relative flex justify-between md:py-4 md:items-center md:after:bg-black md:after:dark:bg-white md:after:h-[1px] md:after:w-[100vw] md:after:absolute md:after:-left-10 md:after:bottom-0">
      <div className="flex flex-col">
        <p className="text-purple-lighter font-medium md-2:hidden">
          {props.pageInfo}
        </p>
        <h2 className="leading-tight text-2xl font-bold lg:text-3xl">
          {props.currentPage}
        </h2>
      </div>

      <div>
        <div className="hidden md:flex items-center gap-6 lg:gap-10">
          <button className="text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
            <FaSearch className="w-[18px] h-[18px]" />
          </button>

          <button className="relative text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
            <FaBell className="w-[18px] h-[18px]" />
            <div className="bg-light-bg dark:bg-dark-bg p-0.5 rounded-full absolute -top-1.5 -right-1.5">
              <div className="bg-pale-green w-2.5 h-2.5 rounded-full"></div>
            </div>
          </button>

          <button className="flex items-center gap-1.5 text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter transition duration-200 ease-linear">
            <IoExtensionPuzzleSharp className="w-[18px] h-[18px]" />
            <p className="font-semibold text-base">Apps</p>
          </button>

          <button className="text-black flex items-center gap-1.5 bg-purple-lighter px-4 py-1.5 border border-black font-semibold transition duration-200 ease-linear hover:bg-purple-lighter-hover">
            <IoAddCircleSharp className="w-5 h-5" />
            <p className="font-bold">Create new</p>
          </button>
        </div>

        <div className="md:hidden">
          <img
            src="https://dailypost.ng/wp-content/uploads/2019/09/Xhaka.jpg"
            alt=""
            className="w-10 h-10 rounded-full object-fill"
          />
        </div>
      </div>
    </header>
  );
};

export default Header;
