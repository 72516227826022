import React from "react";
import { FaAngleLeft } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { Link } from "react-router-dom";
import AuthCard from "../components/Login-Signup/AuthCard";
import PasswordFieldWithLabel from "../components/Login-Signup/PasswordFieldWithLabel";
import RingAndCardsDesign from "../components/Login-Signup/RingAndCardsDesign";
import TextFieldWithLabel from "../components/Login-Signup/TextFieldWithLabel";
import Dropdown from "../components/common/Dropdown";
import PrimaryButton from "../components/common/PrimaryButton";

const SignupPage = () => {
  const countryList = [
    "Country A",
    "Country B",
    "Country C",
    "Country D",
    "Country E",
    "Country F",
    "Country G",
    "Country H",
    "Country I",
    "Country J",
  ];

  return (
    <div className="bg-container bg-[url('./assets/images/light-bg.jpg')] dark:bg-[url('./assets/images/dark-bg.jpg')] bg-contain bg-no-repeat min-h-[100vh] flex bg-light-bg dark:bg-dark-bg">
      <div className="w-full flex flex-col items-center sm:flex-row-reverse">
        <RingAndCardsDesign />

        <AuthCard>
          <Link to="/">
            <button className="border dark:border-white p-1 rounded-[3px] sm:hidden">
              <FaAngleLeft className="text-xl text-black dark:text-white" />
            </button>
          </Link>
          <div className="w-full flex flex-col gap-8">
            <div>
              <h1 className="dark:text-white">Sign up</h1>

              <p className="text-dark-slate">
                Before we start, please enter your current location
              </p>
            </div>

            <form className="flex flex-col gap-4">
              <Dropdown
                dropdownList={countryList}
                dropdownLabel="Country/Area of Residence"
                dropdownPlaceholder="Select a country"
              />

              <TextFieldWithLabel
                label="Email"
                type="email"
                placeholder="Enter your email"
                icon={HiMail}
              />

              <PasswordFieldWithLabel
                label="Password"
                placeholder="Enter your password"
              />

              <div className="flex flex-col gap-2 mb-2">
                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    name="email-updates"
                    id="email-updates"
                    className="custom-checkbox relative top-1 dark:border-white"
                  />
                  <label htmlFor="email-updates" className="dark:text-white">
                    I agree to receive email updates
                  </label>
                </div>

                <div className="flex gap-2">
                  <input
                    type="checkbox"
                    name="terms-of-service"
                    id="terms-of-service"
                    className="custom-checkbox relative top-1 dark:border-white"
                  />
                  <label
                    htmlFor="terms-of-service"
                    className=" dark:text-white"
                  >
                    I have read and agree to the Terms of Service
                  </label>
                </div>
              </div>

              <PrimaryButton btnLabel="Create Account" />
            </form>

            <div className="hidden sm:flex flex-col items-center gap-1 lg:flex-row lg:gap-2 2xl:mt-[10%]">
              <p className="dark:text-white">Already registered?</p>
              <Link to="/signin" className="font-bold dark:text-white">
                Sign in to your account
              </Link>
            </div>
          </div>
        </AuthCard>
      </div>
    </div>
  );
};

export default SignupPage;
