import React from "react";

const PrimaryButton = (props) => {
  return (
    <button className="bg-purple-lighter text-sm sm:text-lg text-black font-bold rounded-[4px] shadow-blackish dark:shadow-grayish border border-black py-3 px-5 sm:px-8 w-full hover:bg-purple-lighter-hover transition duration-200">
      {props.btnLabel}
    </button>
  );
};

export default PrimaryButton;
