import React from "react";
import { BsPeopleFill } from "react-icons/bs";
import { CgProfile } from "react-icons/cg";
import { FiMoreHorizontal } from "react-icons/fi";
import { RxDashboard } from "react-icons/rx";
import BrandLogo from "../../assets/images/brandLogo.png";

const Sidebar = () => {
  return (
    <div className="fixed z-20 bg-white dark:bg-black bottom-0 w-full md-2:bg-black md-2:w-[280px] md-2:min-h-[100vh] md-2:flex md-2:flex-col md-2:justify-between md-2:py-5">
      <div className="md-2:flex md-2:flex-col md-2:gap-10">
        <div className="hidden md-2:block md-2:px-8">
          <img src={BrandLogo} alt="" className="w-28" />
        </div>

        <nav className="w-full md-2:px-4">
          <p className="hidden text-white opacity-75 mb-2 md-2:block md-2:ml-4">
            Navigation
          </p>

          <ul className="flex items-center justify-between px-6 py-4 border-t md-2:flex-col md-2:gap-2 md-2:items-start md-2:border-none md-2:p-0">
            <li className="text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter cursor-pointer transition duration-200 ease-linear md-2:text-white md-2:flex md-2:items-center md-2:gap-3 md-2:w-full md-2:px-4 md-2:py-2.5 md-2:rounded-md-2 md-2:hover:bg-dark-bg">
              <RxDashboard className="w-5 h-5" />
              <p className="hidden font-semibold md-2:block">Dashboard</p>
            </li>

            <li className="text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter cursor-pointer transition duration-200 ease-linear md-2:text-white md-2:flex md-2:items-center md-2:gap-3 md-2:w-full md-2:px-4 md-2:py-2.5 md-2:rounded-md-2 md-2:hover:bg-dark-bg">
              <CgProfile className="w-5 h-5" />
              <p className="hidden font-semibold md-2:block">Profile</p>
            </li>

            <li className="text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter cursor-pointer transition duration-200 ease-linear md-2:text-white md-2:flex md-2:items-center md-2:gap-3 md-2:w-full md-2:px-4 md-2:py-2.5 md-2:rounded-md-2 md-2:hover:bg-dark-bg">
              <BsPeopleFill className="w-5 h-5" />
              <p className="hidden font-semibold md-2:block">Integration</p>
            </li>

            <li className="text-black dark:text-white hover:text-purple-lighter hover:dark:text-purple-lighter cursor-pointer transition duration-200 ease-linear md-2:hidden">
              <FiMoreHorizontal className="w-5 h-5" />
            </li>
          </ul>
        </nav>
      </div>

      <div className="hidden md-2:flex justify-between items-center md-2:px-6">
        <div className="flex items-center gap-2 text-black dark:text-white md-2:text-white">
          <img
            src="https://dailypost.ng/wp-content/uploads/2019/09/Xhaka.jpg"
            alt=""
            className="w-[35px] h-[35px] rounded-full object-fill"
          />
          <p className="font-medium">Granit Xhaka</p>
        </div>

        <div>
          <FiMoreHorizontal className="text-black dark:text-white md-2:w-6 md-2:h-6 md-2:text-white" />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
