import { useEffect, useRef, useState } from "react";
import { FaAngleDown, FaAngleUp } from "react-icons/fa";

const Dropdown = ({ dropdownList, dropdownLabel, dropdownPlaceholder }) => {
  const [selectedOption, setSelectedOption] = useState("");
  const [showOptions, setShowOptions] = useState(false);
  const dropdownRef = useRef(null);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowOptions(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);

    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, [dropdownRef]);

  const handleToggleOptions = () => {
    setShowOptions(!showOptions);
  };

  const handleOptionSelect = (option) => {
    setSelectedOption(option);
    setShowOptions(false);
  };

  return (
    <div className="relative" ref={dropdownRef}>
      <div className="flex flex-col gap-2">
        <label className="font-medium dark:text-white">{dropdownLabel}</label>

        <button
          type="button"
          className="w-full p-4 bg-white dark:bg-black flex items-center justify-between gap-2 border dark:border-white dark:text-white dark:border-white transition-all duration-200 ease-linear hover:border-purple-lighter focus:border-purple-lighter"
          id="options-menu"
          aria-haspopup="true"
          aria-expanded={showOptions}
          onClick={handleToggleOptions}
        >
          {selectedOption ? selectedOption : `${dropdownPlaceholder}`}

          {showOptions ? (
            <FaAngleUp className="text-xl dark:text-white" />
          ) : (
            <FaAngleDown className="text-xl dark:text-white" />
          )}
        </button>
      </div>

      {showOptions && (
        <div
          className="dropdown-options w-full absolute z-10 right-0 mt-2 shadow-lg bg-white dark:bg-black max-h-40 overflow-y-scroll"
          role="menu"
          aria-orientation="vertical"
          aria-labelledby="options-menu"
        >
          <div className="w-full">
            {dropdownList.map((option) => (
              <button
                key={option}
                onClick={() => handleOptionSelect(option)}
                className="w-full flex items-center p-4 bg-white dark:bg-black dark:text-white text-sm font-medium hover:bg-[#f5f2f2] dark:hover:bg-[#0e0e0e] focus:outline-none"
                role="menuitem"
              >
                {option}
              </button>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default Dropdown;
